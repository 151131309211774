import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const Aligner = makeShortcode("Aligner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "no-more-audiences",
      "style": {
        "position": "relative"
      }
    }}>{`No More Audiences`}<a parentName="h1" {...{
        "href": "#no-more-audiences",
        "aria-label": "no more audiences permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We began this page with a lot more content, but were stopped by a simple insight: culture is happening, it is not archived. It is a word uniquely slanted to the present tense.`}</p>
    <p>{`Our stance now is that you will see how `}<a parentName="p" {...{
        "href": "/build/culture"
      }}>{`we approach culture by being with us`}</a>{`, rather than reading our Resources section. We leave only the slightest indications for you to consider. `}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Rhea Myers`}</strong></p>
          <p>{`One of the first people in the world to begin thinking about art and crypto, way back in the distant past of 2012. This blog is full of hidden gems.`}</p>
          <Button to="https://rhea.art/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Enjoy
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Future of Fandom`}</strong></p>
          <p>{`An incredible deck from Zoe. H/T to Adrian le Bas for sharing it.`}</p>
          <Button to="https://docs.google.com/presentation/d/1E_0-hpaaXdqiGNr06SkpnOglkGPiACSDLK6Tu8CS4vg/edit?usp=sharing" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Cheer Along
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column',
          margin: '20px 0px'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Open The Metaverse`}</strong></p>
          <p>{`6529 tweeting some deep truths.`}</p>
          <Button to="https://twitter.com/punk6529/status/1445468399656595456" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Sing It
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <Aligner center mdxType="Aligner">
      <pre><code parentName="pre" {...{}}>{`Three pointers to help you
triangulate the moon.

What good are they
when it grows in you,
each one of us a petri dish
for the play of god,
this endless game
of give and take,
up and down,
fame and fortune.

Move on, my friend,
there is nothing to find.

We are already dust,
a few more bits
in the digital dreaming
that we can be anywhere else
but in the palm of love,
so muster up the courage
to kiss your wound
all the way up.

It may just blossom.
We may just birth a new world.
`}</code></pre>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      